import Vue from 'vue'
import VueRouter from 'vue-router'
import Us from '../views/Us.vue'
import i18n from '@/i18n'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: `/${i18n.locale}`
  },
  {
    path: '/:lang',
    component: {
      render (c) { return c('router-view') }
    },
    children: [
      {
        path: '/',
        name: 'Us',
        component: Us,
      },
      {
        path: 'ask',
        name: 'Ask',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/Ask.vue')
      },
      {
        path: 'blog',
        name: 'Blog',
        component: () => import('../views/Blog.vue')
      },
      {
        path: 'forms',
        name: 'Forms',
        component: () => import('../views/Forms.vue')
      },
      {
        path: 'terms',
        name: 'Terms',
        component: () => import('../views/AllWriteReserved.vue')
      }
    ]
  },
  {
    path: '*',
    redirect: `/${i18n.locale}`
  },
]

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes
})

let DEFAULT_TITLE = 'ΚΟΝΤΟΜΟΥΡΤΟΣ';
router.afterEach((to) => {
  Vue.nextTick(() => {
    document.title = (to.meta.title) ? `${DEFAULT_TITLE} - ${i18n.messages[i18n.locale].nav.meta[to.name]}` : DEFAULT_TITLE;
  });
});

export default router

<template>
  <span>
  <v-container class="fullpage d-flex">
    <v-row align="center" justify="center" no-gutters>
      <v-col cols="12" lg="6">
        <p class="text-h2 text-lg-h1">{{ $t('us.title') }}</p><br>
        <p>{{ $t('us.p') }}</p>
      </v-col>
    </v-row>
  </v-container>
  <Espa/>
  </span>
</template>

<script>
import Espa from '@/components/Espa'
export default {
  name: 'Us',
  components: {
    Espa
  }
}
</script>
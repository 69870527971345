import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify';
import i18n from './i18n'

export const bus = new Vue();

Vue.config.productionTip = false


router.beforeEach((to, from, next) => { // <------------
  let language = to.params.lang;
  if (!language) language = 'el';
  i18n.locale = language;
  next();
})

new Vue({
  router,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')

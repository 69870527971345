import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import colors from 'vuetify/lib/util/colors';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        dark: true,
        themes: {
          light: {
            primary: colors.indigo.darken3,
            secondary: colors.blueGrey.darken3,
            accent: colors.pink.darken4,
            error: colors.red.accent4,
            default: colors.shades.black,
            background_nav: colors.grey.lighten5,
            background: colors.blueGrey.lighten5
          },
          dark: {
            primary: colors.blue.lighten3,
            secondary: colors.yellow.lighten4,
            accent: colors.pink.accent1,
            error: colors.red.lighten2,
            default: colors.shades.white,
            background_nav: colors.grey.darken3,
            background: colors.grey.darken4
          }
        },
        options: {
            customProperties: true
        },
      },
});

<template>
  <v-app id="app">
    <v-main>
      <NavTop/>
      <router-view></router-view>
      <NavBottom/>  
    </v-main>
  </v-app>
</template>
<script>
import NavTop from './components/NavTop';
import NavBottom from './components/NavBottom';

export default {
  name: 'App',
  components: {
    NavBottom,
    NavTop
  },
  mounted(){
    // From 19 to 07 the theme is dark otherwise is light
    let dte = new Date(),
        hrs = dte.getHours();
    //The getHours() method returns the hour (from 0 to 23) of the specified date and time.
    if(hrs > 18 || hrs < 8) this.$vuetify.theme.dark = true
    else this.$vuetify.theme.dark = false
    // -------------- ---------------------//
  }
};
</script>

<style lang="scss">
html { overflow-y: auto }
#app { background-color: var(--v-background-base);}
.v-btn { text-transform: none;}
.v-bottom-navigation{
  align-items: center;
  border-radius: 14px 14px 0px 0px;
}
.fullpage{
  min-height: 82vh;
  padding: 75px 35px;
}
.maxWidth{ max-width: 350px; }
.v-toolbar__content, .v-toolbar__extension{ padding: 4px 16px 4px 35px;}
</style>


<template>
    <v-menu v-model="menu" :close-on-content-click="true" :nudge-width="200" offset-x>
            <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon color="primary">mdi-dots-vertical</v-icon>
                </v-btn>
            </template>
            <v-card color="background_nav">
                <v-list color="background_nav">
                    <v-row>
                        <v-col cols="9">
                            <v-subheader>{{ $t('nav.top.menu.theme[0]') }}</v-subheader>
                            <v-list-item>
                                <v-list-item-action>
                                <v-switch v-model="$vuetify.theme.dark" color="primary" inset></v-switch>
                                </v-list-item-action>
                                <v-list-item-title>{{ $t('nav.top.menu.theme[1]') }}</v-list-item-title>
                            </v-list-item>
                        </v-col>
                    </v-row>
                    <v-subheader>{{ $t('nav.top.menu.lang') }}</v-subheader>
                    <v-row justify="start" class="px-2">
                        <v-col cols="4" v-for="lang in langs" :key="lang.id">
                            <v-btn :color="($i18n.locale == lang.locale)?'primary':''" @click="langBtnsChng(lang.locale)" text x-large>{{ lang.label }}</v-btn>
                        </v-col>
                    </v-row>
                </v-list>
                <CopyWrite/><br>
            </v-card>
        </v-menu>
</template>

<script>
import CopyWrite from './CopyWrite';
export default {
    name: 'NavTopMenu',
    components: {
        CopyWrite
    },
    data: () => ({
      menu: false,
      langs: [
          {id: 1, label: 'GR', locale: 'el'},
          {id: 2, label: 'EN', locale: 'en'},
          //{id: 3, label: 'FR', locale: 'fr'}
        ]
    }),
    methods: {
        langBtnsChng(locale) {
            this.menu = false
            this.$root.$i18n.locale = locale
            this.$router.push({
                params: { lang: locale }
            })
        }
    }
}
</script>
<template>
    <v-container>
        <v-row>
            <v-col cols="12 px-4" >
                <span class="text-caption">
                    <router-link :to="`/${$i18n.locale}/Terms`" class="text-decoration-none">{{ $t('nav.top.menu.terms') }}</router-link><br>
                    © {{year}} kontomourtos.gr ALL RIGHTS RESERVED
                </span>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: 'CopyWrite',
    computed: {
        year() {
            let dt = new Date();
            return dt.getFullYear();
        }
    }
}
</script>
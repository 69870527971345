<template>
    <v-app-bar absolute color="background_nav" class="px-lg-10 px-xl-16" :dark="$vuetify.theme.dark" hide-on-scroll>
        <router-link :to="`/${$i18n.locale}`" class="d-flex text-decoration-none default--text">
            <v-avatar size="35" class="mr-2"><img src="/images/logo.svg" alt="logo"></v-avatar>
            <v-toolbar-title>{{ $t('pagetitle') }}</v-toolbar-title>
        </router-link>
        <v-spacer></v-spacer>
        <span v-show="$vuetify.breakpoint.xlOnly">
            <v-btn text :to="`/${$i18n.locale}`" exact>Εμείς</v-btn>
            <v-btn text :to="`/${$i18n.locale}/Ask`" exact>Ρώτησε</v-btn>
            <v-btn text :to="`/${$i18n.locale}/Blog`" exact>Blog</v-btn>
            <v-btn text :to="`/${$i18n.locale}/Forms`" exact>Forms</v-btn>
        </span>
        <NavTopMenu/>
    </v-app-bar>
</template>

<script>
import NavTopMenu from './NavTopMenu';
export default {
    name: 'NavTop',
    components: {
        NavTopMenu
    },
    data: () => ({
    }),
}
</script>

<style lang="scss">
.v-toolbar__content, .v-toolbar__extension{
  padding: 4px 16px 4px 35px;
}

</style>

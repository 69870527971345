import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
import { VSwitch } from 'vuetify/lib/components/VSwitch';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VMenu,{attrs:{"close-on-content-click":true,"nudge-width":200,"offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"color":"primary"}},[_vm._v("mdi-dots-vertical")])],1)]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c(VCard,{attrs:{"color":"background_nav"}},[_c(VList,{attrs:{"color":"background_nav"}},[_c(VRow,[_c(VCol,{attrs:{"cols":"9"}},[_c(VSubheader,[_vm._v(_vm._s(_vm.$t('nav.top.menu.theme[0]')))]),_c(VListItem,[_c(VListItemAction,[_c(VSwitch,{attrs:{"color":"primary","inset":""},model:{value:(_vm.$vuetify.theme.dark),callback:function ($$v) {_vm.$set(_vm.$vuetify.theme, "dark", $$v)},expression:"$vuetify.theme.dark"}})],1),_c(VListItemTitle,[_vm._v(_vm._s(_vm.$t('nav.top.menu.theme[1]')))])],1)],1)],1),_c(VSubheader,[_vm._v(_vm._s(_vm.$t('nav.top.menu.lang')))]),_c(VRow,{staticClass:"px-2",attrs:{"justify":"start"}},_vm._l((_vm.langs),function(lang){return _c(VCol,{key:lang.id,attrs:{"cols":"4"}},[_c(VBtn,{attrs:{"color":(_vm.$i18n.locale == lang.locale)?'primary':'',"text":"","x-large":""},on:{"click":function($event){return _vm.langBtnsChng(lang.locale)}}},[_vm._v(_vm._s(lang.label))])],1)}),1)],1),_c('CopyWrite'),_c('br')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
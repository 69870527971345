<template>
    <v-bottom-navigation fixed background-color="background_nav" :dark="$vuetify.theme.dark" grow height="75" v-show="$vuetify.breakpoint.lgAndDown">
        <v-btn text :to="`/${$i18n.locale}`" exact>
            <span>{{ $t('nav.bottom[0]') }}</span>
            <v-icon>mdi-emoticon</v-icon>
        </v-btn>
        <v-btn text :to="`/${$i18n.locale}/Ask`" exact>
            <span>{{ $t('nav.bottom[1]') }}</span>
            <v-icon>mdi-comment-multiple</v-icon>
        </v-btn>
        <v-btn text :to="`/${$i18n.locale}/Blog`" exact>
            <span>Blog</span>
            <v-icon>mdi-format-bold</v-icon>
        </v-btn>
        <v-btn text :to="`/${$i18n.locale}/Forms`" exact>
            <span>Forms</span>
            <v-icon>mdi-layers</v-icon>
        </v-btn>
    </v-bottom-navigation>
</template>

<script>
export default {
    name: 'NavBottom',

}
</script>

<style lang="scss" scoped>
.v-btn:not(.v-btn--round).v-size--default{
  height: 75px;
}
.v-btn > .v-btn__content .v-icon{
  margin-bottom: 3px;
}
</style>
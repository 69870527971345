<template>
    <v-container fluid>
        <v-row class="px-sm-6 px-md-6 px-lg-10 px-xl-16" :justify="$vuetify.breakpoint.xsOnly ? 'center' : 'start'">
            <a :href="`/images/espa2022/poster_${$i18n.locale}.pdf`">
                <v-card class="pa-2 white" :width="width" rounded="lg" flat>
                    <v-img :lazy-src="`/images/espa2022/ebanner_${$i18n.locale}.jpg`" :src="`/images/espa2022/ebanner_${$i18n.locale}.jpg`"></v-img>
                </v-card>
            </a>
        </v-row>
    </v-container>
</template>
<script>
  export default {
    computed: {
        width () {
            switch (this.$vuetify.breakpoint.name) {
            case 'xs': return '90vw'
            case 'sm': return '350px'
            case 'md': return '350px'
            case 'lg': return '350px'
            case 'xl': return '450px'
            }
        }
    }
  }
</script>
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VAppBar,{staticClass:"px-lg-10 px-xl-16",attrs:{"absolute":"","color":"background_nav","dark":_vm.$vuetify.theme.dark,"hide-on-scroll":""}},[_c('router-link',{staticClass:"d-flex text-decoration-none default--text",attrs:{"to":`/${_vm.$i18n.locale}`}},[_c(VAvatar,{staticClass:"mr-2",attrs:{"size":"35"}},[_c('img',{attrs:{"src":"/images/logo.svg","alt":"logo"}})]),_c(VToolbarTitle,[_vm._v(_vm._s(_vm.$t('pagetitle')))])],1),_c(VSpacer),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.$vuetify.breakpoint.xlOnly),expression:"$vuetify.breakpoint.xlOnly"}]},[_c(VBtn,{attrs:{"text":"","to":`/${_vm.$i18n.locale}`,"exact":""}},[_vm._v("Εμείς")]),_c(VBtn,{attrs:{"text":"","to":`/${_vm.$i18n.locale}/Ask`,"exact":""}},[_vm._v("Ρώτησε")]),_c(VBtn,{attrs:{"text":"","to":`/${_vm.$i18n.locale}/Blog`,"exact":""}},[_vm._v("Blog")]),_c(VBtn,{attrs:{"text":"","to":`/${_vm.$i18n.locale}/Forms`,"exact":""}},[_vm._v("Forms")])],1),_c('NavTopMenu')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }